@import '../colors';

.ectosense-button {
  height: 34px;

  padding: 0 20px;

  font-size: 14px;
  line-height: 34px;
  text-align: center;

  background-color: $brand-primary;
  color: #ffffff;

  border: none;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: $brand-secondary;
  }

  &.is-large {
    height: 40px;
    line-height: 40px;
  }

  &.is-full-width {
    width: 100%;
  }
}
