@import "../colors";

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-button {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &[disabled] {
    svg {
      animation: spin 2s linear;
    }
  }
}
