@import './../../styles/abstracts/variables';
@import './../../styles/abstracts/functions';
@import './../../styles/abstracts/mixins';
@import './../../styles/colors.scss';

.auth-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .card {
    max-width: 500px;
    min-width: 300px;
    width: 80vw;
    margin: 50px 0;
    .card-top-image {
      position: absolute;
      left: calc(50% - 35px);
      top: -35px;

      display: flex;

      justify-content: center;
      align-items: center;

      background-color: $background-color-1;

      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 50%;
      padding: 12px;

      svg {
        width: 45px;
        height: 45px;
      }
    }

    .card-header {
      padding-top: 55px;
      padding-bottom: 20px;
      font-size: 24px;
      background-color: $background-color-1;
    }

    .card-body {
      padding: 30px;
    }
  }

  .footerlink {
    color: $cyan-dark;
    &.left {
      position: absolute;
      bottom: -25px;
      left: 0;
    }
    &.right {
      position: absolute;
      bottom: -25px;
      right: 0;
      @include media-breakpoint-down(sm) {
        bottom: -50px;
        right: auto;
        left: 0;
      }
    }
  }

  .success-button,
  .success-button button.ui.disabled.button {
    opacity: 1 !important;
  }
}

.informed-consent-checkbox {
  margin-top: $spacer;
}

.card-body.invitation {
  .show-invitation {
    p {
      font-size: 20px;
      color: $dark;
    }
  }
}
