.btn-primary {
  color: #fff;
}

.modal-content {
  background-color: $gray-100;
}

.badge {
  padding: 0.4rem 0.8rem;

  &.orange {
    background-color: $orange;
  }
}

.form-label {
  color: $gray-600;
  font-weight: 600;
}

.dropdown-item {
  &.icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.6rem;
    }
  }
}
