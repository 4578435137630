@import "../abstracts/variables";

textarea {
  resize: none;
}

// Provides validation styling for non RSInput elements
.validation-error-group {
  .react-date-picker .react-date-picker__button {
    border: solid 1px $warning !important;
  }

  .validation-error-text {
    color: $warning;
    font-size: 12px;
    margin-top: 4px;
    font-weight: $font-weight-normal;
  }
}
