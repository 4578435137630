@import '../colors';

.pagination {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  &__previous,
  &__next {
    display: none;
  }

  &__item {
    a {
      display: inline-flex;

      justify-content: center;
      align-items: center;

      width: 28px;
      height: 28px;

      font-size: 14px;
      line-height: 1;

      background-color: $subtle-grey;
      color: $body-color;

      border: 1px solid $border-color;
      border-radius: 4px;

      cursor: pointer;

      &:hover,
      &:focus {
        font-weight: 700;

        text-decoration: none;

        outline: none;
      }
    }

    &.is-selected a {
      font-weight: 700;

      background-color: $cyan-medium;
      color: #ffffff;

      border-color: $brand-secondary;
    }
  }

  &__item + &__item {
    margin-left: 10px;
  }
}

.paged-table {
  .pagination {
    padding: 20px;

    .break {
      padding: 0 10px;
    }
  }
}
