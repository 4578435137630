@import '~bourbon/core/bourbon';

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'vendor/bootstrap';
@import './colors';

@import 'overrides/bootstrap-override.scss';

@import 'components/toggle';
@import 'components/icon-button';
@import 'components/ectosense-button';
@import 'components/forms';
@import 'components/pagination';

@import url('https://fonts.googleapis.com/css?family=Barlow');

body {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  background: $background-color;

  #root {
    height: 100%;
    .App {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 62px;
    }
  }

  .ui.modal {
    position: static;
  }

  .navbar {
    z-index: 1050;
  }
}

.container.content {
  margin-bottom: 70px; // Make room for the Feedback button
}

.button-row {
  display: flex;
  > *:not(:first-child) {
    margin-left: 0.5em;
  }
}

.modal {
  > .close.icon {
    top: 0.5rem;
    right: 0.5rem;
    color: $gray;
  }
}

.btn-toolbar>*+* {
  margin-left: .5em;
}